<template>
  <header class="header">
    <div class="topbar bg__color--ter d-none d-sm-block">
      <div class="container topbar__wrapper d-flex">
        <div class="d-flex align-items-center topbar__infos">
          <a
            :href="`mailto:${translate(email)}`"
            class="link__color--white-prim link--naked email"
            v-if="translate(email)"
          >
            <fa-icon
              class="email__icon"
              :icon="['fas', 'envelope']"
            />

            {{ translate(email) }}
          </a>

          <app-social-networks
            :facebook="['fab', 'facebook-f']"
            :linkedin="['fab', 'linkedin']"
          />
        </div>

        <ul class="call_to_actions d-flex align-items-center justify-content-center">
          <li
            v-for="({ link }, index) in cta"
            :key="`cta_${index}`"
            class="call_to_actions__li"
          >
            <app-link
              :link="link"
              class="cta btn btn__solid--white btn__size--classic"
            />
          </li>

          <li class="call_to_actions__li">
            <app-session-button
              class="session link__color--white-prim"
              @click="show_mobile_menu = false"
              @close="show_mobile_menu = false"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="container header__wrapper">
      <router-link
        :to="{ name: 'Home', params: { locale } }"
        class="logo margin__r--12"
      >
        <img
          class="logo__img"
          :src="translate(logo) || client.logo.url"
          :alt="client.name"
        >
      </router-link>

      <div>
        <nav
          class="navigation"
          :class="{ 'd-block': show_mobile_menu }"
        >
          <ul class="navigation__ul">
            <li
              v-for="({ type, link, button, sub_links = [] }, index) in navigation"
              :key="`navigation_${index}`"
              class="navigation__li"
            >
              <button
                class="navigation__link link--naked link__color--white-ter d-flex align-items-center"
                @click="toggleSubMenu(index)"
                v-if="type === 'button'"
              >
                {{ translate(button) }}

                <fa-icon
                  class="navigation__arrow"
                  :icon="['far', 'chevron-down']"
                  fixed-width
                  v-if="sub_links.length"
                />
              </button>

              <app-link
                :link="link"
                class="navigation__link link--naked link__color--white-ter d-flex align-items-center"
                v-else
              >
                <template #default="{ text }">
                  {{ text }}

                  <fa-icon
                    class="navigation__arrow"
                    :icon="['far', 'chevron-down']"
                    fixed-width
                    v-if="sub_links.length"
                  />
                </template>
              </app-link>

              <ul
                class="navigation__submenu"
                :class="{ 'navigation__submenu--showed': sub_menu === index }"
                v-if="sub_links.length"
              >
                <li
                  v-for="({ sub_link }, i) in sub_links"
                  :key="`navigation_${index}_${i}`"
                >
                  <app-link
                    :link="sub_link"
                    class="navigation__sublink link--naked link__color--white-ter"
                  />
                </li>
              </ul>
            </li>

            <li
              class="navigation__li navigation__li--language"
              v-if="client_languages.length"
            >
              <language-switcher class="language-switcher">
                <template #selected-option="{ option }">
                  <span class="language-switcher__option">
                    <img
                      class="language-switcher__flag"
                      :src="`https://koalect-images.s3.eu-west-3.amazonaws.com/flags/flag_${option.id}.png`"
                      :alt="`Flag of ${option.label.name}`"
                    >

                    {{ option.label.name }}
                  </span>
                </template>

                <template #option="{ option }">
                  <span class="language-switcher__option">
                    <img
                      class="language-switcher__flag"
                      :src="`https://koalect-images.s3.eu-west-3.amazonaws.com/flags/flag_${option.id}.png`"
                      :alt="`Flag of ${option.label.name}`"
                    >

                    {{ option.label.name }}
                  </span>
                </template>

                <template #open-indicator>
                  <fa-icon
                    class="language-switcher__arrow"
                    :icon="['far', 'chevron-down']"
                    fixed-width
                  />
                </template>
              </language-switcher>
            </li>

            <li class="navigation__li navigation__li--session d-sm-none">
              <app-session-button
                class="session link__color--white-prim"
                @click="show_mobile_menu = false"
                @close="show_mobile_menu = false"
              />
            </li>

            <template v-if="cta.length">
              <li
                v-for="({ link }, index) in cta"
                :key="`cta_${index}`"
                class="navigation__li d-sm-none"
              >
                <app-link
                  :link="link"
                  class="link__color--ter link--naked navigation__cta"
                />
              </li>
            </template>
          </ul>
        </nav>

        <app-burger-menu
          class="burger d-md-none"
          :state="show_mobile_menu"
          @toggle="toggleMobileMenu"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, defineAsyncComponent, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@/stores/ClientStore'
import { useLanguageStore } from '@/stores/LanguageStore'
import { useI18n } from '@/vendors/i18n'
import { useRoute } from 'vue-router'

import AppSocialNetworks from '&/molecules/AppSocialNetworks'
const AppLink = defineAsyncComponent(() => import('&/atoms/AppLink'))
const AppBurgerMenu = defineAsyncComponent(() => import('&/atoms/AppBurgerMenu'))
const LanguageSwitcher = defineAsyncComponent(() => import('&/atoms/LanguageSwitcher'))
const AppSessionButton = defineAsyncComponent(() => import('&/organisms/AppSessionButton'))

const { section } = defineProps({
  section: { type: Object, required: true }
})

const route = useRoute()
const { translate } = useI18n()
const { getClient } = useClientStore()
const { locale, client_languages } = storeToRefs(useLanguageStore())

const { client } = getClient()

const { topbar = {}, logo = {}, navigation = [] } = section.attributes
const { email = null, cta = [] } = topbar

const show_mobile_menu = ref(false)
const sub_menu = ref(null)

const toggleMobileMenu = () => {
  scrollTo()

  setTimeout(() => show_mobile_menu.value = !show_mobile_menu.value, 100)
}

const toggleSubMenu = index => sub_menu.value !== index ? sub_menu.value = index : sub_menu.value = null

watch(route, () => show_mobile_menu.value = false)
</script>

<style lang="scss" scoped>
$text_color: #575758;

:deep(.cta) {
  font-size: rem(12px);
  text-transform: uppercase;
  padding: 10px 20px;
  display: block;
  border: 1px solid $white;
  border-radius: 5px;
  font-weight: 600;

  &:hover {
    color: $text_color;
    background: $color--primary;
  }
}

.header {
  background: $text_color;
  position: relative;
  z-index: 999;

  &__wrapper {
    padding-block: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.topbar {
  padding-block: 20px;

  &__wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @include mq(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__infos {
    gap: 26px;
  }
}

.email {
  &__icon {
    margin-right: 10px;
  }
}

:deep() {
  .networks {
    &__ul {
      display: flex;
      font-size: rem(15px);
      gap: 20px;
    }

    &__a {
      color: $white;

      &:hover {
        color: $color--primary;
      }
    }
  }
}

.call_to_actions {
  gap: 20px;
}

:deep() {
  .session {
    font-size: rem(12px);
    text-transform: uppercase;
  }
}

.logo {
  display: block;

  &__img {
    width: auto;
    height: 40px;

    @include mq(sm) {
      height: 60px;
    }

    @include mq(md) {
      height: 80px;
    }
  }
}

.navigation {
  font-size: rem(17px);
  line-height: 1.4;
  position: absolute;
  width: 100%;
  background: $text_color;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  padding-inline: 12px;
  display: none;

  @include mq(md) {
    position: relative;
    display: block;
    background: none;
    transform: none;
    padding: 0;
    white-space: nowrap;
  }

  &__ul {
    max-width: 720px;
    margin: 0 auto;

    @include mq(md) {
      display: flex;
      align-items: center;
      max-width: none;
      margin: 0;
    }
  }

  &__li {
    position: relative;

    &--session {
      border-bottom: 1px solid rgba(#fff, 0.5);
      padding: 10px 15px;
    }

    &--session,
    &--language {
      border-top: 1px solid rgba(#fff, 0.5);

      @include mq(md) {
        border: none;
      }
    }

    &--language {
      @include mq(md) {
        &::before {
          content: '';
          display: block;
          width: 0;
          height: 3px;
          background: $color--primary;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.3s cubic-bezier(.58,.3,.005,1);
        }
      }
    }

    @include mq(md) {
      &:hover {
        &::before {
          width: 100%;
        }

        .navigation {
          &__arrow {
            transform: rotate(-180deg);
          }

          &__submenu {
            display: block;
          }
        }

        :deep() {
          .navigation__link {
            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__submenu {
    display: block;
    max-height: 0;
    transition: all .3s ease-in-out;
    overflow: hidden;

    &--showed {
      max-height: 600px;
    }

    @include mq(md) {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      background: $text_color;
      max-height: inherit;
    }
  }

  &__arrow {
    font-size: rem(10px);
    margin-left: 10px;
    transition: .3s ease-in-out;
  }

  :deep() {
    .navigation {
      &__link {
        font-style: italic;
        text-transform: uppercase;
        display: block;
        padding: 15px 6px;
        position: relative;
        transition: .3s ease-in-out;

        @include mq(md) {
          font-family: $font-family--secondary;
        }

        @include mq(lg) {
          padding-inline: 10px;
        }

        &.is-active,
        &:hover {
          color: $color--primary;

          @include mq(md) {
            &::before {
              width: 100%;
            }
          }
        }

        &::before {
          content: '';
          display: block;
          width: 0;
          height: 3px;
          background: $color--primary;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.3s cubic-bezier(.58,.3,.005,1);
        }
      }

      &__cta {
        padding: 15px 6px;
        display: block;
        text-transform: uppercase;
      }

      &__sublink {
        padding: 10px 36px;
        transition: .3s ease-in-out;
        display: block;
        font-size: rem(17px);
        line-height: 1.17;

        &:hover {
          color: $color--primary;
        }
      }
    }
  }
}

.language-switcher {
  justify-content: flex-start;

  &__flag {
    width: 18px;
    height: auto;
  }

  &__option {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__arrow {
    transition: .3s ease-in-out;

    .vs--open & {
      transform: rotate(-180deg);
    }
  }

  :deep() {
    .locale-switcher {
      background: none;
    }

    .v-select {
      border: none;
    }

    .vs {
      &__dropdown-toggle {
        color: $white;
        padding: 15px 10px;

        @include mq(lg) {
          padding-inline: 10px;
        }

        &:hover {
          color: $color--primary;

          .vs {
            &__selected {
              color: $color--primary;
            }
          }
        }
      }

      &__selected {
        color: $white;
        font-style: italic;
        text-transform: uppercase;
        font-size: rem(17px);
        line-height: 1.4;
        font-family: $font-family--secondary;
      }

      &__actions {
        font-size: rem(10px);
      }

      &__search {
        &:focus {
          border: 0;
        }
      }

      &__dropdown-menu {
        border: 0;
        background: $text_color;
        min-width: 170px;
      }

      &__dropdown-option {
        color: $white;
        font-family: $font-family--primary;
        font-size: rem(17px);
        line-height: 1.2;
        padding: 10px 15px;

        &--highlight {
          color: $color--primary;
          background: none;
        }
      }
    }
  }
}

.burger {
  color: $color--tertiary;

  :deep() {
    .menu {
      &__label {
        display: none;
      }

      &__line {
        background-color: $color--tertiary;
      }

      &__close {
        &::before,
        &::after {
          background-color: $color--tertiary;
        }
      }
    }
  }
}
</style>
